<template>
  <div class="aboutOurCompany">
    <div class="title"><h3>الوظائف الحاليه</h3></div>

    <v-divider></v-divider>
    <v-container>
      <div>
        <v-row>
          <v-col cols="12" lg="8" sm="10" xs="10">
            <div v-if="checkAdsJobs">
              <h2>
                {{ checkAdsJobs.title }}
              </h2>
              <div v-if="checkAdsJobs.image_path">
                <v-img
                  :src="
                    'https://backend.ascww.org/api/careers/image/' +
                      checkAdsJobs.image_path
                  "
                ></v-img>
              </div>
              <div id="app" v-if="checkAdsJobs.file_path">
                <!--  <PDFJSViewer :path="`${path}`" :fileName="`${name}`" />-->
                <PDFJSViewer
                  :path="
                    'https://backend.ascww.org/api/careers/file/' +
                      checkAdsJobs.file_path
                  "
                  :fileName="checkAdsJobs.file_name"
                />
              </div>
            </div>

            <v-img
              v-else
              :lazy-src="loadingLink"
              src="https://www.pua.edu.eg/wp-content/uploads/2020/08/no-vacanciesar.png"
            >
            </v-img>
            <!--  <div>
              <div>
                <h2>
                  تعلن عن مسابقة رقم ( 1 ) لسنة 2022م لشغل 3 وظائف بقطاع الوادي
                  الجديد.
                </h2>
                <div id="app">
                  <PDFJSViewer :path="`${path}`" :fileName="`${name}`" />
                </div>
              </div>
              <br />
            </div> -->
          </v-col>
          <v-col cols="12" lg="4" sm="4" xs="4">
            <Link></Link>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
\
<script>
import Link from "../../components/link.vue";
import PDFJSViewer from "../../components/PDFJSViewer.vue";
export default {
  data() {
    return {
      name: "jobs_ads.pdf", //change which pdf file loads
      path: "lib/web/viewer.html", //path of the PDF.js viewer.html
      loadingLink: require("@/assets/Images/load.svg"),
    };
  },
  components: { Link, PDFJSViewer },
  created() {
    this.$store.dispatch("findAllCareers");
  },
  computed: {
    allCareers() {
      return this.$store.state.careers;
    },
    checkAdsJobs() {
      return this.allCareers.find((item) => item.type_id == 1);
    },
  },
  methods: {},
};
</script>
<style>
h3 {
  font-family: Arial, Helvetica, sans-serif;
}
.changeTitleStyle {
  background: rgba(222, 184, 135, 0.192) !important;
}
</style>
